<template>
  <v-container fluid fill-height class="pa-0">
    <JitsiMeet
      v-if="jwt"
      :lobbyName="$route.params.lobbyName"
      :jwt="jwt"
      :meetingDuration="timeData"
      @onMeetingJoin="onMeetingJoin"
      @onMeetingEnd="onMeetingEnd"
    >
    </JitsiMeet>
  </v-container>
</template>

<script>
import JitsiMeet from "../components/global/JitsiMeet.vue";
export default {
  components: { JitsiMeet },
  props: {
    lobbyName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    jwt: "",
    isMeetingJoined: false,
    meetingReportId: null,
    meeting_report_id: "",
    meeting_report_id_session: "",
    timeData: 0,
    meetingDuration: 0,
  }),

  mounted() {
    this.generateToken();
  },

  methods: {
    generateToken() {
      return this.$api
        .post("/meetings/room-subject-meetings/access-token/", {
          lobby_name: this.lobbyName,
        })
        .then((res) => {
          this.timeData = res.data.time_data;
          return res;
        })

        .then((res) => (this.jwt = res.data.token))
        .catch((err) => {
          if (this.$route.query.jwt) {
            this.jwt = this.$route.query.jwt;
          }
        });
    },
    onMeetingJoin() {
      this.isMeetingJoined = true;

      this.$api
        .post("/meetings/meetings_session/start_video/", {
          lobby_name: this.lobbyName,
        })
        .then((res) => {
          this.meetingReportId = res.data.meeting_report_id;
          sessionStorage.setItem("meetingReportId", this.meetingReportId);
        })
        .catch((err) => {
          if (err.response.status == 403) {
            alert("Your trial period has ended.");
            this.$router.push({ name: "Profile" });
          }
        });
    },

    onMeetingEnd() {
      if (this.isMeetingJoined) {
        this.isMeetingJoined = false;
        const storedMeetingReportId = sessionStorage.getItem("meetingReportId");
        console.log("salim data " + storedMeetingReportId);

        const requestData = { end_time: new Date() };

        this.$api
          .put(
            `/meetings/meetings_session/${storedMeetingReportId}/`,
            requestData
          )
          .then((response) => {
            console.log("Meeting ended successfully:");
          })
          .catch((error) => {
            console.error("Error ending video:", error);
          });
      }
    },
  },
};
</script>

<style>
</style>
