<template>
  <div ref="jitsiContainer" style="height: 100%; width: 100%"></div>
</template>

<script>
export default {
  emits: {
    onMeetingJoin: null,
    onMeetingEnd: null,
  },
  props: {
    lobbyName: {
      type: String,
      required: true,
    },
    jwt: {
      type: String,
      default: "",
    },
    meetingDuration: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      isMeetingJoined: false,
    };
  },
  async mounted() {
    await this.loadJitsiScript().then(() => {
      this.loading = true;
      this.initJitsi();
      this.loading = false;
      if (this.$store.getters["user/isTeacher"] && this.meetingDuration) {
        let elapsedTime = 0;
        const timerInterval = setInterval(() => {
          elapsedTime += 1000;

          if (
            elapsedTime >= parseInt(this.meetingDuration, 10) *60* 1000 &&
            this.isMeetingJoined
          ) {
            clearInterval(timerInterval);
            this.endMeeting();
          }
        }, 1000);
      }
    });
  },

  methods: {
    loadJitsiScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src =
          "https://" + process.env.VUE_APP_JITSI_BASEURL + "/external_api.js";
        document.head.appendChild(script);
        script.onload = () => {
          return resolve();
        };
        script.onerror = () => {
          return reject();
        };
      });
    },
    initJitsi() {
      const options = {
        jwt: this.jwt,
        roomName: this.lobbyName,
        parentNode: this.$refs.jitsiContainer,
        startWithAudioMuted: true,
        startWithVideoMuted: true,
        // maxParticipants: 1

      };
      this.meet = new window.JitsiMeetExternalAPI(
        process.env.VUE_APP_JITSI_BASEURL,
        options
      );

      this.meet.addListener("videoConferenceJoined", (participant) => {
        if (!this.isMeetingJoined) {
          this.isMeetingJoined = true;
          this.$emit("onMeetingJoin", participant);
        }
      });

      this.meet.addListener("videoConferenceLeft", () => {
        if (this.isMeetingJoined) {
          this.isMeetingJoined = false;
          this.$emit("onMeetingEnd");
        }
      });
    },
    endMeeting() {
      if (this.isMeetingJoined) {
        this.isMeetingJoined = false;
        this.$emit("onMeetingEnd");
        this.destroyJitsiInstance(); // Destroy the Jitsi instance
      }
    },
    destroyJitsiInstance() {
      if (this.meet) {
        this.meet.dispose(); // Destroy the Jitsi instance
        this.meet = null;
      }
    },
  },
};
</script>

<style></style>
